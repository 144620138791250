<template>
  <div class="table">
    <header class="header">
      <p>若全部停用，则站点导航模块停用</p>
    </header>
    <DragTable :list-query="list" :header="header" :sort="true" @change="handle">
      <template #status="{ row }">
        <span class="status" :class="{ active: row.state !== 2 }">{{
          row.state === 2 ? `已启用` : `已停用`
        }}</span>
      </template>
      <template #control="{row}">
        <div class="control">
          <span :class="{'active': row.state === 2}" @click="open(row)">{{row.state === 2 ? `停用` : `启用`}}</span>
        </div>
      </template>
    </DragTable>
  </div>
</template>
<script>
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  setup(props, { root }) {
    const header = [
      { name: "序号", value: "sort", width: 100 },
      { name: "站点ID", value: "id", width: 170 },
      { name: "站点名", value: "site_name" },
      { name: "更新时间", value: "update_time", width: 220 },
      { name: "操作人", value: "operation_user" },
      { name: "状态", value: "state", width: 229, slot: "status" },
      { name: "操作", value: "", slot: "control", width: 232 },
    ];
    const list = reactive({data: []});
    const getList = ()=>{
      try {
        root.$axios.get('/cms/site/entrance/list')
        .then(res=>{
          list.data = res.data
        })
      } catch (error) {
        console.error(error)
      }
    }
    getList()
    const open = async (data) => {
      try {
        const confirm = await root.$goDialog('确定要'+(data.state === 2 ? '停用':'启用')+'吗？')
        if(!confirm) return
        const res = await root.$axios.post('/cms/site/entrance/set_state', {
          id: data.id,
          state: data.state !== 2 ? 2 : 1
        })
        if(res.code=== 10000) {
          root.$message.success(`${data.state === 2 ? '停用':'启用'}成功`)
          getList()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const handle = async (data) => {
      try {
        const arr = []
        data.forEach(item => {
          arr.push({ id: item.id, sort: item.sort })
        });
        const res = await root.$axios.post('/cms/site/entrance/sort',{
          sort_list: arr
        })
        if(res.code !== 10000) {
          root.$message.error(res.msg)
          getList()
        }
      } catch (error) {
        console.error(error)
      }
    }
    return {
      list,
      header,
      open,
      handle
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin circle() {
  position: absolute;
  content: " ";
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: $safeColor;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
}
.title-style{
  @include textover();
}
.status {
  position: relative;
  color: $safeColor;
  padding-left: 13px;
  &::after {
    @include circle();
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &.active {
    color: $wordColor;
    &::after {
      background-color: $wordColor;
    }
  }
}
.control {
  display: flex;
  span {
    margin-right: 15px;
    cursor: pointer;
    display: block;
    &:nth-of-type(1) {
      color: $mainColor;
    }
    &.active{
      color: $dangerColor;
    }
  }
}
</style>
